






import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class ComicLink extends Vue {
  @Prop(String)
  private name!: string;

  @Prop(String)
  private path!: number;

  @Prop(String)
  private backgroundImage?: string;

  @Prop(String)
  private smallBackgroundImage?: string;

  @Prop()
  private arrow?: string | boolean;

  private imageLoaded = false;

  mounted() {
    this.updateBg();
  }

  @Watch("backgroundImage")
  private updateBg() {
    this.imageLoaded = false;
    let img: HTMLImageElement | null = this.getDetachedImage();
    if (img) {
      img.onload = () => {
        this.imageLoaded = true;
        img = null;
      };
    }
  }

  private getDetachedImage() {
    if (!this.backgroundImage) {
      return null;
    }
    let result = document.createElement("img");
    result.src = this.backgroundImage;
    return result;
  }

  private get style() {
    if (!this.backgroundImage || (!this.imageLoaded && !this.smallBackgroundImage)) {
      return "";
    }
    let image = this.imageLoaded ? this.backgroundImage : this.smallBackgroundImage;
    return {
      "background-image": "url('" + image + "')",
    };
  }

  private get arrowDirection() {
    if (this.arrow === "left" || this.arrow === "right") {
      return this.arrow;
    } else if (this.arrow) {
      return "none";
    }
    return "";
  }
}
